import * as React from "react";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import RiotBar from "@riotgames/riotbar-react";
import Seo, { SeoData } from "../components/Seo/Seo";
import Purchase, { GameEdition, GameLogo } from "../components/PurchasePage/Purchase";
import { useSiteMetadata } from "../hooks/use-site-metadata";

import { StyleSheetManager } from "styled-components";
import griddie from "styled-griddie";

interface PageData {
  purchase: {
    purchaseSeo: SeoData;
    heading: string;
    subheading: string;
    purchaseLinksHeading: string;
    gameEditions: GameEdition[];
    gameLogo: GameLogo;
  };
}

interface Props extends PageProps<PageData> {}

const PurchasePage: React.FC<Props> = ({ data }) => {
  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();
  const { purchaseSeo } = data.purchase;

  const purchaseUrl = `https://${siteDomain}/${language}/purchase/`;

  return (
    <StyleSheetManager stylisPlugins={[griddie]}>
      <main>
        <Seo data={purchaseSeo} url={purchaseUrl} locale={language} />
        <RiotBar product="ruinedking" locale={language} />
        <Purchase data={data.purchase} withBackground={true} />
      </main>
    </StyleSheetManager>
  );
};

export default PurchasePage;

export const query = graphql`
  query ($language: String!) {
    purchase: contentstackCasperPurchasePage(locale: { eq: $language }) {
      ...PurchaseSeoFragment
      ...PurchaseLandingFragment
    }

    ...TranslationQueryFragment
  }
`;
